import React, { useEffect, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import T from 'prop-types';
import get from 'lodash.get';
import { Masonry } from 'masonic';

import {
  SectionHeader,
  SectionHeadline,
  SectionTitle,
  SectionSuptitle,
  SectionBody,
  sectionCardGroupHeaderGrid,
  SectionCardGroup
} from '../styles/section';
import { CardBlog, CardProject } from '../components/cards';
import useMediaAware from '../utils/media-query-aware-hook';
import { prepareAuthorProperties } from '../utils/utils';
import { MasonryItemsListElement } from '../styles/masonry';

const latestItemsRenderFn = ({ index, data }) => {
  const { slug } = data;
  const { title, media } = data.frontmatter;
  const cardImage = get(media, 'card.url.childImageSharp.fluid');

  if (data.internal.type === 'Blog') {
    const { excerpt, date, timeToRead } = data;
    const { topics, authors, teaser } = data.frontmatter;

    const authorsFields = prepareAuthorProperties(authors);

    return (
      <CardBlog
        title={title}
        slug={slug}
        readTime={timeToRead}
        cardImage={cardImage}
        authors={authorsFields}
        topics={topics}
        date={date}
        excerpt={teaser || excerpt}
        index={index + 1}
      />
    );
  }

  if (data.internal.type === 'Project') {
    const { client } = data.frontmatter;
    return (
      <CardProject
        title={title}
        slug={slug}
        cardImage={cardImage}
        client={client}
        index={index + 1}
        skin='dark'
      />
    );
  }

  return null;
};

const SectionLatestWork = ({ excludeId }) => {
  const [ref, media] = useMediaAware();

  const data = useStaticQuery(graphql`
    query {
      latestProject: allProject(
        filter: { published: { eq: true } }
        limit: 1
        sort: { order: DESC, fields: date }
      ) {
        nodes {
          internal {
            type
          }
          ...ProjectCardData
        }
      }

      latestBlog: allBlog(
        filter: { published: { eq: true } }
        limit: 4
        sort: { order: DESC, fields: date }
      ) {
        nodes {
          id
          internal {
            type
          }
          ...BlogCardData
        }
      }
    }
  `);

  const latestItems = useMemo(() => {
    // We're getting 4 blog posts to ensure we always have at least 3.
    const nodes = data.latestBlog.nodes
      .filter((n) => n.id !== excludeId)
      .slice(0, 3);
    return [...nodes, ...data.latestProject.nodes];
  }, [data, excludeId]);

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  return (
    <SectionCardGroup>
      <SectionHeader grid={sectionCardGroupHeaderGrid}>
        <SectionHeadline>
          <SectionTitle>What we&apos;re doing.</SectionTitle>
          <SectionSuptitle>Latest</SectionSuptitle>
        </SectionHeadline>
      </SectionHeader>
      <SectionBody>
        <Masonry
          items={latestItems}
          overscanBy={Infinity}
          columnGutter={
            media
              ? media.isXsmallDown()
                ? 16
                : media.isLargeDown()
                ? 32
                : 48
              : null
          }
          columnCount={media ? (media.isSmallDown() ? 1 : 2) : null}
          columnWidth={288}
          as={MasonryItemsListElement}
          itemAs='li'
          itemKey={(item) => item.id}
          render={latestItemsRenderFn}
        />
      </SectionBody>
    </SectionCardGroup>
  );
};

SectionLatestWork.propTypes = {
  // The SectionLatestWork the last 3 blog posts and 1 project, but we should
  // not display a duplicated blog post when we're viewing the latest blog post
  // page. The excludeId prop allows us to remove an id from the list.
  excludeId: T.string
};

export default SectionLatestWork;
