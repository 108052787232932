import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import get from 'lodash.get';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageSuptitle,
  InpageMetaDetails,
  InpageBody,
  InpageLead
} from '../../styles/inpage';
import {
  AuthorAvatar,
  AuthorsAvatarsList
} from '../../components/author-avatar';
import Media from '../../components/media';
import BlockLayout from '../../components/block-layout';
import SectionLatestWork from '../../components/latest-work';
import InpageNavigation from '../../components/inpage-nav';
import { prepareAuthorProperties } from '../../utils/utils';
import SectionRelated from '../../components/section-related';

const EstTime = styled.dd`
  &::before {
    content: '— ';
  }
`;

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const { previous, next, id } = pageContext;
  const post = data.blogPost;
  const { html, date, timeToRead } = post;
  const { title, teaser, lead, authors, media, topics, blocks, related } =
    post.frontmatter;

  // The next and previous from graphQl represent the order on the list.
  // Since posts are ordered chronologically, the previous post is the next on
  // the list.
  const nextPost = previous
    ? {
        url: `/blog/${previous.slug}`,
        linkTitle: 'Visit previous blog post',
        title: previous.frontmatter.title
      }
    : null;
  const prevPost = next
    ? {
        url: `/blog/${next.slug}`,
        linkTitle: 'Visit next blog post',
        title: next.frontmatter.title
      }
    : null;

  const layoutBlocks = html
    ? [
        ...(blocks || []),
        {
          type: 'text',
          body: html
        }
      ]
    : blocks;

  const coverImage = get(media, 'cover.url.childImageSharp.fluid');
  const coverImageAttribution = get(media, 'cover.attribution');

  const authorsFields = prepareAuthorProperties(authors);

  return (
    <Layout
      location={location}
      title={title}
      description={teaser || post.excerpt}
      metaImage={coverImage && coverImage.src}
    >
      <Inpage>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>{title}</InpageTitle>
              <InpageSuptitle>
                <Link to='/blog' title='View Blog page'>
                  Blog
                </Link>
              </InpageSuptitle>
              <InpageMetaDetails>
                <dt>Estimated</dt>
                <EstTime>{timeToRead} min read</EstTime>
              </InpageMetaDetails>
              {authorsFields.length > 1 ? (
                <AuthorsAvatarsList authors={authorsFields} date={date} />
              ) : authorsFields.length === 1 ? (
                <AuthorAvatar
                  url={`/team/${authorsFields[0].slug}`}
                  name={authorsFields[0].name}
                  image={authorsFields[0].avatar}
                  date={date}
                />
              ) : (
                <AuthorAvatar date={date} />
              )}
            </InpageHeadline>
            {lead && (
              <InpageLead>
                <p>{lead}</p>
              </InpageLead>
            )}
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded={!!coverImage}>
          {coverImage && (
            <Media
              size='cover'
              decoration='bottom'
              fluid={coverImage}
              attribution={coverImageAttribution}
              alt='Blog post cover image'
            />
          )}
          <BlockLayout blocks={layoutBlocks} />

          {!!related?.length && <SectionRelated related={related} />}

          <InpageNavigation
            topics={topics}
            prevPost={prevPost}
            nextPost={nextPost}
          />

          <SectionLatestWork excludeId={id} />
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default BlogPostTemplate;

BlogPostTemplate.propTypes = {
  data: T.object,
  location: T.object,
  pageContext: T.shape({
    id: T.string,
    next: T.object,
    previous: T.object
  })
};

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    blogPost: blog(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      date
      frontmatter {
        title
        teaser
        topics
        lead
        media {
          ...CoverImageFluid
        }
        blocks {
          ...BlockLayout
        }
        related {
          ...RelatedContent
        }
        authors {
          slug
          frontmatter {
            title
            media {
              avatar {
                url {
                  childImageSharp {
                    fixed(width: 40, height: 40) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
